import * as React from 'react'
import { Icon, Card, Form, Message } from 'semantic-ui-react'
import { History } from 'history'

interface AcaoPrincipalRequerimentoExistentFormProps {
  history: History
}

export class AcaoPrincipalRequerimentoExistentForm extends React.PureComponent<AcaoPrincipalRequerimentoExistentFormProps> {
  state = {
    cpf: '',
    identificador: '',
    formError: false
  }

  handleChange = (e: any, { name, value }: any) =>
    this.setState({ [name]: value })

  handleSubmit = () => {
    if (
      this.state.cpf.trim() === '' ||
      this.state.identificador.trim() === ''
    ) {
      this.setState({ formError: true })
    } else {
      this.setState({ formError: false })
      this.props.history.push(
        `/requerimento/${this.state.identificador}/${this.state.cpf}/editar`
      )
    }
  }

  render() {
    const { cpf, identificador } = this.state

    return (
      <div>
        <Card>
          <Card.Content>
            <Card.Header>
              <Icon name="edit" /> Requerimento Existente
            </Card.Header>{' '}
            <Card.Description>
              Visualizar ou editar requerimento existente.
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <div className="ui two buttons">
              <Form error={this.state.formError}>
                <Message
                  error
                  content="Campos CPF e Identificador devem estar preenchidos"
                />
                <Form.Input
                  label="CPF:"
                  placeholder="000.000.000-00"
                  name="cpf"
                  value={cpf}
                  onChange={this.handleChange}
                  required
                />
                <Form.Input
                  label="Identificador:"
                  placeholder="Identificador"
                  name="identificador"
                  value={identificador}
                  onChange={this.handleChange}
                  required
                />
                <Form.Button
                  content="Visualizar / Editar"
                  onClick={this.handleSubmit}
                />
              </Form>
            </div>
          </Card.Content>
        </Card>
      </div>
    )
  }
}
