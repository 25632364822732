import React from 'react'

interface PdfPreviewPropsProps {
  content: any
}

export class PdfPreview extends React.PureComponent<PdfPreviewPropsProps> {
  render() {
    return (
      <iframe
        title={'pdfPreview'}
        height={'100%'}
        width={'100%'}
        src={this.props.content}
      />
    )
  }
}
