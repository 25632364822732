import * as React from 'react'
import { Button, Icon, Card, Form, Select } from 'semantic-ui-react'
import { History } from 'history'
import { TipoDocumentoEnum } from '../types/TipoDocumentoEnum'

interface AcaoPrincipalProps {
  history: History
}

interface AcaoPrincipalState {
  tipo: string
}

export class AcaoPrincipalNovoRequerimento extends React.PureComponent<
  AcaoPrincipalProps,
  AcaoPrincipalState
> {
  state: AcaoPrincipalState = {
    tipo: TipoDocumentoEnum.AVERBACAO_REGISTRO
  }

  tiopDocOptions = [
    {
      key: 'a',
      text: 'Ato de Averbação/Registro',
      value: TipoDocumentoEnum.AVERBACAO_REGISTRO
    }
  ]

  novoRequerimentoClick = () => {
    this.props.history.push(`/requerimento/novo`)
  }

  render() {
    return (
      <Card.Group centered>
        <Card>
          <Card.Content>
            <Card.Header>
              <Icon name="add" /> Novo Requerimento
            </Card.Header>{' '}
            <Card.Description>
              Clique no botão abaixo para criar um novo requerimento.
            </Card.Description>
            <Card.Content extra>
              <div className="ui two buttons">
                <Form>
                  <Form.Field
                    control={Select}
                    options={this.tiopDocOptions}
                    label={{
                      children: 'Tipo:',
                      htmlFor: 'form-select-control-tipo'
                    }}
                    defaultValue={this.state.tipo}
                    placeholder="Tipo"
                    searchInput={{ id: 'form-select-control-tipo' }}
                    selection
                  />
                  <Button onClick={this.novoRequerimentoClick} type="submit">
                    Criar Novo Requerimento
                  </Button>
                </Form>
              </div>
            </Card.Content>
          </Card.Content>
        </Card>
      </Card.Group>
    )
  }
}
