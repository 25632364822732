import dateFormat from 'dateformat'
import { History } from 'history'
import * as React from 'react'
import {
  Button,
  Checkbox,
  Container,
  Form,
  Grid,
  Header,
  List,
  Message,
  Segment,
  TextArea,
  TransitionablePortal
} from 'semantic-ui-react'
import { RequerimentoSubmetidoModal } from './modalMessages/RequerimentoSubmetidoModal'
import {
  createRequerimento,
  getUploadUrl,
  uploadFile
} from '../api/requerimentos-api'
import { CreateRequerimentoRequest } from '../types/CreateRequerimentoRequest'
import { getPdfAsBase64, getPdfAsBytes } from '../utils/pdfTransformer'
import { PdfPreview } from './PdfPreview'
import uuid from 'react-uuid'
import { uuidReadable } from '../utils/utils'
import { Buffer } from 'buffer'

interface RequerimentoFormNovoProps {
  history: History
  showHideLoader: any
}

export class RequerimentoFormNovo extends React.PureComponent<RequerimentoFormNovoProps> {
  state = {
    identificador: '',
    pdfUrl: '',
    // Dados pessoais:
    nome: 'Flaviano Flauber', // nome: '',
    cpf: '032.208.484-94', // cpf: '',
    nacionalidade: '',
    profissao: '',
    endereco: '',
    email: 'meuuser@domaing.com', // email: '',
    // Ato de Averbacao / Registro
    numMatriculaDoLivro2: '',
    lote: '999', // lote: '',
    quadraDoLotCondominioRuaSitio: '',
    // Documentos anexos Da pessoa física
    averbacaoDeQualidadeSubjetiva: false,
    // Documentos anexos Do imovel
    averbacaoDeConstrucao: false,
    demolicao: false,
    numeroPredial: false,
    inscricaoImobiliaria: false,
    incorporacaoDeCondominio: false,
    registroDeLoteamento: false,
    alteracaoDeRuaExpansao: false,
    denominacaoDeRua: false,
    afetacaoDoImovelAIncorporacaoDeCondominioRegistrada: false,
    desmembramento: false,
    unificacao: false,
    retificacaoDaArea: false,
    georeferenciamento: false,
    ccirIncra: false,
    car: false,
    baixaDeAlFiduciaria: false,
    baixaDeHipoteca: false,
    baixaDeCcirIncra: false,
    // Documentos anexos outros
    outros: '',
    // Usamos no estado do componente
    openRequerimentoSubmetidoModal: false,
    srcContent: '',
    openPdfPreview: false,
    formError: false
  }

  handleChange = (e: any, { name, value }: any) =>
    this.setState({ [name]: value })

  handleClick = (event: any, data: any) => {
    this.setState({ [data.name]: data.checked })
  }

  handleSubmit = () => {
    if (
      this.state.cpf.trim() === '' ||
      this.state.nome.trim() === '' ||
      this.state.email.trim() === ''
    ) {
      this.setState({ formError: true })
    } else {
      this.setState({ formError: false })
      this.props.showHideLoader()
      this.onRequerimentoCreate()
    }
  }

  onRequerimentoCreate = async () => {
    try {
      const id = uuidReadable(uuid()) + '.pdf'
      const uploadUrl = await getUploadUrl(id)

      const createRequerimentoRequest: CreateRequerimentoRequest = {
        pdfUrl: id,
        // Dados pessoais:
        nome: this.state.nome,
        cpf: this.state.cpf,
        nacionalidade: this.state.nacionalidade,
        profissao: this.state.profissao,
        endereco: this.state.endereco,
        email: this.state.email,
        // Ato de Averbacao / Registro
        numMatriculaDoLivro2: this.state.numMatriculaDoLivro2,
        lote: this.state.lote,
        quadraDoLotCondominioRuaSitio: this.state.quadraDoLotCondominioRuaSitio,
        // Documentos anexos Da pessoa física
        averbacaoDeQualidadeSubjetiva: this.state.averbacaoDeQualidadeSubjetiva,
        // Documentos anexos Do imovel
        averbacaoDeConstrucao: this.state.averbacaoDeConstrucao,
        demolicao: this.state.demolicao,
        numeroPredial: this.state.numeroPredial,
        inscricaoImobiliaria: this.state.inscricaoImobiliaria,
        incorporacaoDeCondominio: this.state.incorporacaoDeCondominio,
        registroDeLoteamento: this.state.registroDeLoteamento,
        alteracaoDeRuaExpansao: this.state.alteracaoDeRuaExpansao,
        denominacaoDeRua: this.state.denominacaoDeRua,
        afetacaoDoImovelAIncorporacaoDeCondominioRegistrada:
          this.state.afetacaoDoImovelAIncorporacaoDeCondominioRegistrada,
        desmembramento: this.state.desmembramento,
        unificacao: this.state.unificacao,
        retificacaoDaArea: this.state.retificacaoDaArea,
        georeferenciamento: this.state.georeferenciamento,
        ccirIncra: this.state.ccirIncra,
        car: this.state.car,
        baixaDeAlFiduciaria: this.state.baixaDeAlFiduciaria,
        baixaDeHipoteca: this.state.baixaDeHipoteca,
        baixaDeCcirIncra: this.state.baixaDeCcirIncra,
        // Documentos anexos outros
        outros: this.state.outros
      }

      const pdfBytes = await getPdfAsBytes(
        false,
        createRequerimentoRequest.nome,
        createRequerimentoRequest.email,
        createRequerimentoRequest.cpf,
        createRequerimentoRequest.lote,
        false
      )
      await uploadFile(uploadUrl, Buffer.from(pdfBytes))
      const newRequerimento = await createRequerimento(
        createRequerimentoRequest
      )
      this.setState({
        identificador: newRequerimento.identificador,
        pdfUrl: newRequerimento.pdfUrl
      })
      console.debug(newRequerimento)
    } catch (e) {
      console.error('Falha na criação do requerimento! Error: ', e)
    }
    this.props.showHideLoader()
    this.handleOpenModal()
  }

  async handleAbrirPreVisualizacaoDoPDFClick() {
    const value = await getPdfAsBase64(
      true,
      this.state.nome,
      this.state.cpf,
      this.state.email,
      this.state.lote
    )
    this.setState({
      openPdfPreview: true,
      srcContent: value
    })
  }

  closeRequerimentoSucessoModal() {
    this.setState({
      openRequerimentoSubmetidoModal: false
    })
  }

  handleFecharPreVisualizacaoDoPDFClick = () =>
    this.setState({ openPdfPreview: false })

  render() {
    const {
      nome,
      cpf,
      nacionalidade,
      profissao,
      endereco,
      email,
      numMatriculaDoLivro2,
      lote,
      quadraDoLotCondominioRuaSitio,
      openPdfPreview
    } = this.state

    return (
      <div>
        <Form error={this.state.formError}>
          <Form.Group>
            <Container>
              <Header as="h1">Dados para o requerimento</Header>
              <Message
                error
                content="Campos Nome, CPF e E-mail devem estar preenchidos"
              />
              <List>
                <List.Content>
                  <List.Header style={{ fontWeight: 'bold' }}>
                    Dados pessoais
                  </List.Header>
                  <List.List>
                    <List.Item>
                      <List.Content>
                        <List.Header>
                          <Grid container columns={2} stackable>
                            <Grid.Column>
                              <Form.Input
                                label="Nome"
                                placeholder="Seu nome"
                                name="nome"
                                value={nome}
                                onChange={this.handleChange}
                                required
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Form.Input
                                label="CPF:"
                                placeholder="000.000.000-00"
                                name="cpf"
                                value={cpf}
                                onChange={this.handleChange}
                                required
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Form.Input
                                label="Nacionalidade:"
                                placeholder="Sua nacionalidade"
                                name="nacionalidade"
                                value={nacionalidade}
                                onChange={this.handleChange}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Form.Input
                                label="Profissão:"
                                placeholder="Sua profissão"
                                name="profissao"
                                value={profissao}
                                onChange={this.handleChange}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Form.Input
                                label="Endereço:"
                                placeholder="Seu endereço"
                                name="endereco"
                                value={endereco}
                                onChange={this.handleChange}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Form.Input
                                label="E-mail:"
                                placeholder="Seu e-mail"
                                name="email"
                                value={email}
                                onChange={this.handleChange}
                                required
                              />
                            </Grid.Column>
                          </Grid>
                        </List.Header>
                      </List.Content>
                    </List.Item>
                  </List.List>
                </List.Content>
              </List>
              <List>
                <List.Content>
                  <List.Header style={{ fontWeight: 'bold' }}>
                    Ato de Averbacao / Registro
                  </List.Header>
                  <List.List>
                    <List.Item>
                      <List.Content>
                        <List.Header>
                          <Grid container columns={2} stackable>
                            <Grid.Column>
                              <Form.Input
                                label="N. Matricula do livro 2"
                                placeholder="N. Matricula do livro 2"
                                name="numMatriculaDoLivro2"
                                value={numMatriculaDoLivro2}
                                onChange={this.handleChange}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Form.Input
                                label="Lote:"
                                placeholder="Lote"
                                name="lote"
                                value={lote}
                                onChange={this.handleChange}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Form.Input
                                label="Quadra do Lot./Condominio/Rua/Sitio"
                                placeholder="Quadra do Lot./Condominio/Rua/Sitio"
                                name="quadraDoLotCondominioRuaSitio"
                                value={quadraDoLotCondominioRuaSitio}
                                onChange={this.handleChange}
                              />
                            </Grid.Column>
                          </Grid>
                        </List.Header>
                      </List.Content>
                    </List.Item>
                  </List.List>
                </List.Content>
              </List>
              <List>
                <List.Content>
                  <List.Header style={{ fontWeight: 'bold' }}>
                    Documentos anexos
                  </List.Header>
                  <List.List>
                    <List.Item>
                      <List.Content>
                        <List.Header></List.Header>
                        <List>
                          <List.Content>
                            <List.Header style={{ fontWeight: 'bold' }}>
                              Da pessoa física
                            </List.Header>
                            <List.List>
                              <List.Item>
                                <List.Content>
                                  <List.Header>
                                    <Grid container columns={1} stackable>
                                      <Grid.Column>
                                        <Checkbox
                                          toggle
                                          label="Averbacao de qualidade subjetiva (casamento e inclusao / alteracao de dados do conjuge)"
                                          name="averbacaoDeQualidadeSubjetiva"
                                          onClick={this.handleClick}
                                        />
                                      </Grid.Column>
                                    </Grid>
                                  </List.Header>
                                </List.Content>
                              </List.Item>
                            </List.List>
                          </List.Content>
                        </List>
                        <List>
                          <List.Content>
                            <List.Header style={{ fontWeight: 'bold' }}>
                              Do imovel
                            </List.Header>
                            <List.List>
                              <List.Item>
                                <List.Content>
                                  <List.Header>
                                    <Grid container columns={2} stackable>
                                      <Grid.Column>
                                        <Checkbox
                                          toggle
                                          label="Averbacao de construcao"
                                          name="averbacaoDeConstrucao"
                                          onClick={this.handleClick}
                                        />
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Checkbox
                                          toggle
                                          label="Demolicao"
                                          name="demolicao"
                                          onClick={this.handleClick}
                                        />
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Checkbox
                                          toggle
                                          label="Numero Predial"
                                          name="numeroPredial"
                                          onClick={this.handleClick}
                                        />
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Checkbox
                                          toggle
                                          label="Inscricao Imobiliaria"
                                          name="inscricaoImobiliaria"
                                          onClick={this.handleClick}
                                        />
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Checkbox
                                          toggle
                                          label="Incorporacao de condominio"
                                          name="incorporacaoDeCondominio"
                                          onClick={this.handleClick}
                                        />
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Checkbox
                                          toggle
                                          label="Registro de Loteamento"
                                          name="registroDeLoteamento"
                                          onClick={this.handleClick}
                                        />
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Checkbox
                                          toggle
                                          label="Alteracao de rua (expansao)"
                                          name="alteracaoDeRuaExpansao"
                                          onClick={this.handleClick}
                                        />
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Checkbox
                                          toggle
                                          label="Denominacao de rua"
                                          name="denominacaoDeRua"
                                          onClick={this.handleClick}
                                        />
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Checkbox
                                          toggle
                                          label="Afetacao do imovel a incorporacao de condominio registrada"
                                          name="afetacaoDoImovelAIncorporacaoDeCondominioRegistrada"
                                          onClick={this.handleClick}
                                        />
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Checkbox
                                          toggle
                                          label="Desmembramento"
                                          name="desmembramento"
                                          onClick={this.handleClick}
                                        />
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Checkbox
                                          toggle
                                          label="Unificacao (Fusao)"
                                          name="unificacao"
                                          onClick={this.handleClick}
                                        />
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Checkbox
                                          toggle
                                          label="Retificacao da area"
                                          name="retificacaoDaArea"
                                          onClick={this.handleClick}
                                        />
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Checkbox
                                          toggle
                                          label="Georeferenciamento"
                                          name="georeferenciamento"
                                          onClick={this.handleClick}
                                        />
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Checkbox
                                          toggle
                                          label="C.C.I.R/INCRA"
                                          name="ccirIncra"
                                          onClick={this.handleClick}
                                        />
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Checkbox
                                          toggle
                                          label="C.A.R."
                                          name="car"
                                          onClick={this.handleClick}
                                        />
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Checkbox toggle label="N.I.R.F./RFB" />
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Checkbox
                                          toggle
                                          label="Baixa de Al. Fiduciaria"
                                          name="baixaDeAlFiduciaria"
                                          onClick={this.handleClick}
                                        />
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Checkbox
                                          toggle
                                          label="Baixa de Hipoteca"
                                          name="baixaDeHipoteca"
                                          onClick={this.handleClick}
                                        />
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Checkbox
                                          toggle
                                          label="Baixa de C.C.I.R/INCRA"
                                          name="baixaDeCcirIncra"
                                          onClick={this.handleClick}
                                        />
                                      </Grid.Column>
                                    </Grid>
                                  </List.Header>
                                </List.Content>
                              </List.Item>
                            </List.List>
                          </List.Content>
                        </List>
                        <List>
                          <List.Content>
                            <List.Header style={{ fontWeight: 'bold' }}>
                              Outros
                            </List.Header>
                            <List.List>
                              <List.Item>
                                <List.Content>
                                  <List.Header>
                                    <Grid container columns={1} stackable>
                                      <Grid.Column>
                                        <Form.Field
                                          name="outros"
                                          control={TextArea}
                                          onChange={this.handleChange}
                                          type="text"
                                        />
                                      </Grid.Column>
                                    </Grid>
                                  </List.Header>
                                </List.Content>
                              </List.Item>
                            </List.List>
                          </List.Content>
                        </List>
                      </List.Content>
                    </List.Item>
                  </List.List>
                </List.Content>
              </List>
              <Grid container>
                <Grid.Column textAlign="center" columns={3}>
                  <Segment.Inline>
                    <Button
                      type="button"
                      onClick={this.props.history.goBack}
                      content="Voltar"
                    />
                    <Button
                      type="submit"
                      onClick={this.handleSubmit}
                      content="Criar"
                    />

                    <Button
                      button="button"
                      content="Visualizar"
                      icon="file pdf"
                      onClick={this.handleAbrirPreVisualizacaoDoPDFClick.bind(
                        this
                      )}
                    />

                    <TransitionablePortal open={openPdfPreview}>
                      <Segment
                        inverted
                        textAlign="center"
                        style={{
                          height: '100%',
                          width: '100%',
                          position: 'fixed',
                          left: '0%',
                          top: '0%',
                          zIndex: 1000,
                          paddingTop: '50px',
                          paddingRight: '30px',
                          paddingBottom: '60px'
                        }}
                      >
                        <Header>
                          <Button
                            type="button"
                            content="Fechar"
                            negative={true}
                            onClick={this.handleFecharPreVisualizacaoDoPDFClick.bind(
                              this
                            )}
                          />
                        </Header>
                        <PdfPreview content={this.state.srcContent} />
                      </Segment>
                    </TransitionablePortal>
                  </Segment.Inline>
                </Grid.Column>
              </Grid>
            </Container>
          </Form.Group>
        </Form>
        <RequerimentoSubmetidoModal
          history={this.props.history}
          openRequerimentoSubmetidoModal={
            this.state.openRequerimentoSubmetidoModal
          }
          onClose={this.closeRequerimentoSucessoModal.bind(this)}
          identificador={this.state.identificador}
          cpf={this.state.cpf}
          url={this.state.pdfUrl}
        />
      </div>
    )
  }

  handleOpenModal() {
    this.setState({ openRequerimentoSubmetidoModal: true })
  }

  calculateDueDate(): string {
    const date = new Date()
    date.setDate(date.getDate() + 7)

    return dateFormat(date, 'yyyy-mm-dd') as string
  }
}
