import * as React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { History } from 'history'

interface RequerimentoNaoEditavelModalProps {
  history: History
  openRequerimentoNaoEditavelModal: boolean
  identificador: string
  cpf: string
  onClose: any
}

export class RequerimentoNaoEditavelModal extends React.PureComponent<RequerimentoNaoEditavelModalProps> {
  render() {
    return (
      <Modal
        size={'tiny'}
        onClose={() => this.props.onClose()}
        open={this.props.openRequerimentoNaoEditavelModal}
      >
        <Modal.Header>Requerimento não editável!</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            Requerimento identificador/cpf {this.props.identificador}/
            {this.props.cpf} não está editável.
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" onClick={this.props.onClose} content="Voltar" />
          <Button
            color="black"
            onClick={() => {
              try {
                this.props.onClose()
              } catch (e) {}
              this.props.history.push(`/`)
            }}
          >
            Encerrar
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}
