import * as React from 'react'
import { LogIn } from './LogIn'
import { NotFound } from './NotFound'
import { RequerimentosList } from './RequerimentosList'
import { AcoesPrincipais } from './AcoesPrincipais'
import { Route, Router, Switch } from 'react-router-dom'
import { RequerimentoFormEdit } from './RequerimentoFormEdit'
import { RequerimentoFormNovo } from './RequerimentoFormNovo'
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'
import { Amplify } from 'aws-amplify'
import { cognitoConfig } from '../config'
import { components, formFields } from './Auth/Auth'
import '@aws-amplify/ui-react/styles.css'

Amplify.configure(cognitoConfig)

export interface RouterAndContentAreaProps {
  history: any
  showHideLoader: any
  showAuthentication: boolean
  setShowAuthentication: any
  setShowAuthenticationAndGoToAdmin: any
}

const RouterAndContentArea = (
  propsOfRouterAndContentArea: RouterAndContentAreaProps
) => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus])
  const showLogin = () => {
    const result =
      !propsOfRouterAndContentArea.showAuthentication &&
      authStatus !== 'authenticated'
    return result
  }

  return (
    <div>
      <Router history={propsOfRouterAndContentArea.history}>
        <Switch>
          <Route
            path="/"
            exact
            render={(props) => {
              return (
                <AcoesPrincipais
                  {...props}
                  setShowAuthentication={
                    propsOfRouterAndContentArea.setShowAuthentication
                  }
                />
              )
            }}
          />

          <Route
            path="/admin"
            exact
            render={(props) => {
              console.log('admin called...')
              if (showLogin()) {
                propsOfRouterAndContentArea.history.push(`/login`)
              } else {
                return (
                  <>
                    <Authenticator
                      formFields={formFields}
                      components={components}
                    >
                      {({ signOut }) => (
                        <>
                          <RequerimentosList
                            {...props}
                            showHideLoader={
                              propsOfRouterAndContentArea.showHideLoader
                            }
                            setShowAuthentication={
                              propsOfRouterAndContentArea.setShowAuthentication
                            }
                          />
                        </>
                      )}
                    </Authenticator>
                  </>
                )
              }
            }}
          />

          <Route
            path="/login"
            exact
            render={(props) => {
              console.log('login called...')
              return (
                <LogIn
                  setShowAuthentication={
                    propsOfRouterAndContentArea.setShowAuthentication
                  }
                  setShowAuthenticationAndGoToAdmin={
                    propsOfRouterAndContentArea.setShowAuthenticationAndGoToAdmin
                  }
                />
              )
            }}
          />

          <Route
            path="/requerimento/novo"
            exact
            render={(props) => {
              return (
                <RequerimentoFormNovo
                  {...props}
                  showHideLoader={propsOfRouterAndContentArea.showHideLoader}
                />
              )
            }}
          />

          <Route
            path="/requerimento/:identificador/:cpf/editar"
            exact
            render={(props) => {
              return (
                <RequerimentoFormEdit
                  {...props}
                  showHideLoader={propsOfRouterAndContentArea.showHideLoader}
                  cpfParam={props.match.params.cpf}
                  identificadorParam={props.match.params.identificador}
                />
              )
            }}
          />

          <Route component={NotFound} />
        </Switch>
      </Router>
    </div>
  )
}

export default RouterAndContentArea
