import * as React from 'react'
import { Card } from 'semantic-ui-react'
import { History } from 'history'
import { AcaoPrincipalRequerimentoExistentForm } from './AcaoPrincipalRequerimentoExistentForm'
import { AcaoPrincipalNovoRequerimento } from './AcaoPrincipalNovoRequerimento'

interface AcoesPrincipaisProps {
  history: History
  setShowAuthentication: any
}

interface LogInState {}

export class AcoesPrincipais extends React.PureComponent<
  AcoesPrincipaisProps,
  LogInState
> {
  async componentDidMount() {
    this.props.setShowAuthentication(false)
  }

  render() {
    return (
      <Card.Group centered>
        <AcaoPrincipalNovoRequerimento {...this.props} />
        <AcaoPrincipalRequerimentoExistentForm {...this.props} />
      </Card.Group>
    )
  }
}
