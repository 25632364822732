export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT

export const cognitoConfig = {
  Auth: {
    // Amazon Cognito Region
    region: process.env.REACT_APP_REGION,

    // Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,

    // Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID
  }
}
