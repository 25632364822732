import * as React from 'react'
import { Container, List, Segment } from 'semantic-ui-react'
import { Image, View } from '@aws-amplify/ui-react'

export default class Footer extends React.Component {
  render() {
    return (
      <div>
        <Segment className="footer" inverted vertical>
          <Container textAlign="center">
            <View textAlign="center">
              <Image
                width="25px"
                alt="CRUD-PB logo"
                src="https://docs.amplify.aws/assets/logo-dark.svg"
              />
            </View>
            <List horizontal inverted divided link size="small">
              <List.Item as="a" href="#">
                © 2023 Copyright:{' '}
                <a
                  href="https://crud-pb.com.br"
                  target="_blank"
                  rel="noreferrer"
                >
                  CRUD-PB
                </a>
              </List.Item>

              <List.Item as="a" href="#">
                {process.env.REACT_APP_LAST_GIT_COMMIT_INFO}
              </List.Item>
            </List>
          </Container>
        </Segment>
      </div>
    )
  }
}
