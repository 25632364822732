import * as React from 'react'
import { Button, Form, Icon, Modal } from 'semantic-ui-react'

interface RequerimentoRemoverConfirmacaoModalProps {
  onSuccess: any
  pos: number
  parentState: any
}

export class RequerimentoRemoverConfirmacaoModal extends React.PureComponent<RequerimentoRemoverConfirmacaoModalProps> {
  state = { modalOpen: false }

  handleOpen = () => this.setState({ modalOpen: true })

  handleClose = () => this.setState({ modalOpen: false })

  render() {
    let modalOpen = 'false'

    this.state.modalOpen === true ? (modalOpen = 'true') : (modalOpen = 'false')

    return (
      <Modal
        trigger={
          <Form.Input name="remove">
            <Icon name="remove" color="red" link onClick={this.handleOpen} />
          </Form.Input>
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size="small"
      >
        <Modal.Header>Remover Requerimento</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            Confirmar a remoção do requerimento?
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            onClick={() => {
              this.handleClose()
              this.props.onSuccess(this.props.pos)
            }}
          >
            Sim
          </Button>
          <Button onClick={this.handleClose}>Não</Button>
        </Modal.Actions>
      </Modal>
    )
  }
}
