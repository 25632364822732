import { apiEndpoint } from '../config'
import Axios from 'axios'
import { CreateRequerimentoRequest } from '../types/CreateRequerimentoRequest'
import { Requerimento } from '../types/Requerimento'
import { getIdToken, isUserLoggedIn } from '../utils/utils'

export async function getRequerimentos(
  idToken: string,
  filterConfig: string
): Promise<Requerimento[]> {
  console.log('Fetching requerimentos')

  let filter = '?limit=500'

  filter += filterConfig.trim().length === 0 ? '' : '&' + filterConfig

  const response = await Axios.get(`${apiEndpoint}/requerimentos${filter}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`
    }
  })
  console.log('Requerimentos:', response.data.requerimentos)
  return response.data.requerimentos
}

export async function getRequerimento(
  identificador: string,
  cpf: string
): Promise<Requerimento> {
  console.log('Fetching requerimento')
  let response
  if (isUserLoggedIn()) {
    const params = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getIdToken()}`
      }
    }

    response = await Axios.get(
      `${apiEndpoint}/requerimentos/${identificador}/${cpf}`,
      params
    )
  } else {
    response = await Axios.get(
      `${apiEndpoint}/requerimentos/${identificador}/${cpf}`
    )
  }
  return response.data
}

export async function createRequerimento(
  newRequerimento: CreateRequerimentoRequest
): Promise<Requerimento> {
  const response = await Axios.post(
    `${apiEndpoint}/requerimentos`,
    JSON.stringify(newRequerimento),
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response.data.item
}

export async function updateRequerimento(
  requerimento: Requerimento
): Promise<void> {
  await Axios.put(
    `${apiEndpoint}/requerimentos/${requerimento.identificador}/${requerimento.cpf}`,
    JSON.stringify(requerimento),
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
}

export async function updateRequerimentoEditavelField(
  idToken: string,
  requerimento: Requerimento
): Promise<void> {
  await Axios.put(
    `${apiEndpoint}/requerimentos/${requerimento.identificador}/${requerimento.cpf}/editavel`,
    JSON.stringify({
      on: !(
        requerimento.editavel &&
        new Date(requerimento.editavelAt).getDate() >= new Date().getDate() - 1
      )
    }),
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`
      }
    }
  )
}

export async function updateRequerimentoPublicoField(
  idToken: string,
  requerimento: Requerimento
): Promise<void> {
  await Axios.put(
    `${apiEndpoint}/requerimentos/${requerimento.identificador}/${requerimento.cpf}/publico`,
    JSON.stringify({
      on: !requerimento.publico
    }),
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`
      }
    }
  )
}

export async function deleteRequerimento(
  idToken: string,
  identificador: string,
  cpf: string
): Promise<void> {
  await Axios.delete(`${apiEndpoint}/requerimentos/${identificador}/${cpf}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`
    }
  })
}

export async function getUploadUrl(anexoId: string): Promise<string> {
  const response = await Axios.post(
    `${apiEndpoint}/requerimentos/attachment/${anexoId}`,
    '',
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
  return response.data.uploadUrl
}

export async function uploadFile(
  uploadUrl: string,
  file: Buffer
): Promise<void> {
  await Axios.put(uploadUrl, file)
}
