import * as React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { History } from 'history'

interface RequerimentoNaoPublicoModalProps {
  history: History
  openRequerimentoNaoPublicoModal: boolean
  identificador: string
  cpf: string
  onClose: any
}

export class RequerimentoNaoPublicoModal extends React.PureComponent<RequerimentoNaoPublicoModalProps> {
  render() {
    return (
      <Modal
        size={'tiny'}
        onClose={() => this.props.onClose()}
        open={this.props.openRequerimentoNaoPublicoModal}
      >
        <Modal.Header>Requerimento não acessível!</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            Requerimento identificador/cpf {this.props.identificador}/
            {this.props.cpf} não está acessível.
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            onClick={() => {
              try {
                this.props.onClose()
              } catch (e) {}
              this.props.history.push(`/`)
            }}
          >
            Encerrar
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}
