import * as React from 'react'
import { Menu, Icon, Button } from 'semantic-ui-react'
import { useAuthenticator } from '@aws-amplify/ui-react'

interface AppHeaderProps {
  showHideMainMenuFunction: any
  setShowAuthentication: any
}

const AppHeader = (props: AppHeaderProps) => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus])
  const { user, signOut } = useAuthenticator((context) => [context.user])
  return (
    <div>
      <Menu
        borderless
        style={{
          flexShrink: 0, //don't allow flexbox to shrink it
          borderRadius: 0, //clear semantic-ui style
          margin: 0 //clear semantic-ui style
        }}
      >
        <Menu.Item position="left" onClick={props.showHideMainMenuFunction}>
          <Icon inverted color="black" name="sidebar" />
        </Menu.Item>
        {authStatus === 'authenticated' ? (
          <Menu.Item position="right">
            <Button
              as="a"
              onClick={() => {
                signOut?.call(this)
                props.setShowAuthentication(false)
              }}
            >
              Log out
            </Button>
          </Menu.Item>
        ) : null}
      </Menu>
    </div>
  )
}

export default AppHeader
