import * as React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import { FullPageLoaderConfig } from '../types/FullPageLoaderConfig'

interface FullPageLoaderProps {
  config: FullPageLoaderConfig
}

export default class FullPageLoader extends React.Component<FullPageLoaderProps> {
  render() {
    if (!this.props.config.show) return null
    return (
      <div>
        <Dimmer active>
          <Loader size="large" indeterminate>
            {this.props.config.message}
          </Loader>
        </Dimmer>
      </div>
    )
  }
}
