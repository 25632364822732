import {
  Button,
  Heading,
  Image,
  useAuthenticator,
  useTheme,
  View
} from '@aws-amplify/ui-react'
import { Amplify, I18n } from 'aws-amplify'
import { cognitoConfig } from '../../config'
import { history } from '../../routing'
import { translations } from '@aws-amplify/ui-react'

I18n.putVocabularies(translations)
I18n.setLanguage('en')

Amplify.configure(cognitoConfig)

export const components = {
  Header() {
    const { tokens } = useTheme()

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="CRUD-PB logo"
          src="https://docs.amplify.aws/assets/logo-dark.svg"
        />
      </View>
    )
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme()

      return (
        <>
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Entre com sua Conta
          </Heading>
        </>
      )
    },
    Footer() {
      const { toResetPassword } = useAuthenticator()

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={history.goBack}
            size="small"
            variation="link"
          >
            Voltar
          </Button>
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Resetar Senha
          </Button>
        </View>
      )
    }
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme()

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Criar Conta
        </Heading>
      )
    },
    Footer() {
      const { toSignIn } = useAuthenticator()

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            Volar para Entrar
          </Button>
        </View>
      )
    }
  },

  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme()
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Digite suas informações:
        </Heading>
      )
    }
  },

  SetupTOTP: {
    Header() {
      const { tokens } = useTheme()
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Digite suas informações:
        </Heading>
      )
    }
  },

  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme()
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Digite suas informações:
        </Heading>
      )
    }
  },

  ResetPassword: {
    Header() {
      const { tokens } = useTheme()
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Digite suas informações:
        </Heading>
      )
    }
  },

  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme()
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Digite suas informações:
        </Heading>
      )
    }
  }
}

export const formFields = {
  signIn: {
    username: {
      placeholder: 'Digite seu e-mail'
    }
  },
  signUp: {
    password: {
      label: 'Senha:',
      placeholder: 'Digite sua senha',
      isRequired: false
    },
    confirm_password: {
      label: 'Confirme a senha:'
    }
  },
  forceNewPassword: {
    password: {
      placeholder: 'Digite sua Senha'
    }
  },
  resetPassword: {
    username: {
      placeholder: 'Digite seu e-mail'
    }
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: 'Digite seu código de confirmação',
      label: 'New Label',
      isRequired: false
    },
    confirm_password: {
      placeholder: 'Digite sua senha por favor'
    }
  }
  //   setupTOTP: {
  //     QR: {
  //       totpIssuer: 'test issuer',
  //       totpUsername: 'amplify_qr_test_user'
  //     },
  //     confirmation_code: {
  //       label: 'New Label',
  //       placeholder: 'Enter your Confirmation Code:',
  //       isRequired: false
  //     }
  //   },
  //   confirmSignIn: {
  //     confirmation_code: {
  //       label: 'New Label',
  //       placeholder: 'Enter your Confirmation Code:',
  //       isRequired: false
  //     }
  //   }
}

I18n.putVocabulariesForLanguage('en', {
  // Sign in
  'Sign In': 'Identificação', // Tab header
  'Sign in': 'Entrar', // Button label
  'Sign in to your account': 'Bem-vindo de volta!',
  Username: 'Digite seu e-mail', // Username label
  Password: 'Digite sua senha', // Password label
  'Forgot your password?': 'Resetar Senha',
  'Enter your Password': 'Digite sua senha',
  'Incorrect username or password.': 'E-mail ou senha incorreto.',

  //Sign Up
  'Create Account': 'Cadastro', // Tab header
  'Create a new account': 'New User', // Header text
  'Confirm Password': 'Confirme sua senha', // Confirm Password label
  Email: 'Digite seu e-mail',
  'Phone Number': 'Enter your phone number',
  'Enter your Username': 'Digite seu e-mail',
  'Please confirm your Password': 'Por favor confirme sua Senha',
  'Creating Account': 'Criando Conta',

  // Reset Password
  'Reset your password': 'Esqueceu sua senha?',

  'Send code': 'Resetar minha senha',
  'Back to Sign In': 'Voltar para Autenticação',

  'Signing in': 'Autenticando-se',

  //
  Confirm: 'Confirmar',
  'Enter your code': 'Entre seu código',
  'Resend Code': 'Re-enviar Código',
  'Confirmation Code': 'Código de Confirmação ',
  'Invalid verification code provided, please try again.':
    'Código de verificação provido inválido, por favor tente novamente',
  Confirming: 'Confirmando',

  // Error message
  'Username cannot be empty': 'E-mail não poder estar vazio',
  'Password cannot be empty': 'Senha não pode estar vazio',
  'Your passwords must match': 'As senhas devem bater',
  'Username should be an email.': 'E-mail deve ser válido',
  'Password did not conform with policy: Password not long enough':
    'Senha não conforme: o tamanho da senha de ser de pelo menos 8 caracteres',
  'Password did not conform with policy: Password must have uppercase characters':
    'Senha não conforme: pelo menos 1 caractere deve ser uma letra maiúsculas',
  'Password did not conform with policy: Password must have numeric characters':
    'Senha não conforme: pelo menos 1 caractere deve ser um número',
  'Password did not conform with policy: Password must have symbol characters':
    'Senha não conforme: pelo menos um caractere deve ser especial, ex.: *, #, &',
  'Your code is on the way. To log in, enter the code we emailed to':
    'Seu código esta a caminho. Para autenticar-se, digite o código enviado o e-mail',
  'It may take a minute to arrive': 'Pode levar um minuto para chegar',
  'Please enter a number': 'Por favor digite um número',
  'An account with the given email already exists.':
    'Uma conta com o e-mail provido já existe.'
})
