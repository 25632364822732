import * as React from 'react'
import { Button, Icon, Card } from 'semantic-ui-react'
interface LogInProps {
  setShowAuthentication: any
  setShowAuthenticationAndGoToAdmin: any
}

interface LogInState {}

export class LogIn extends React.PureComponent<LogInProps, LogInState> {
  async componentDidMount() {
    this.props.setShowAuthentication(false)
  }

  render() {
    return (
      <div>
        <Card.Group centered>
          <Card>
            <Card.Content>
              <Card.Header>
                <Icon name="sign in" /> Identificação
              </Card.Header>
              <Card.Description>
                Para gerenciar os requerimentos, é preciso identificar-se
                primeiro
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <div className="ui two buttons">
                <Button
                  onClick={() =>
                    this.props.setShowAuthenticationAndGoToAdmin(true)
                  }
                  size="huge"
                  color="olive"
                >
                  Identificar-se
                </Button>
              </div>
            </Card.Content>
          </Card>
        </Card.Group>
      </div>
    )
  }
}
