import * as React from 'react'
import { Button, Form, Input, Modal } from 'semantic-ui-react'
import { History } from 'history'
import { copyToClipboard } from '../../utils/utils'

interface RequerimentoSubmetidoModalProps {
  history: History
  openRequerimentoSubmetidoModal: boolean
  identificador: string
  cpf: string
  onClose: any
  url: string
}

export class RequerimentoSubmetidoModal extends React.PureComponent<RequerimentoSubmetidoModalProps> {
  render() {
    return (
      <Modal
        size={'tiny'}
        onClose={() => this.props.onClose()}
        open={this.props.openRequerimentoSubmetidoModal}
      >
        <Modal.Header>Requerimento submetido com sucesso!</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <Form.Field readOnly>
                <label>CPF: </label>
                <input defaultValue={this.props.cpf} />
              </Form.Field>
              <Form.Field>
                <label>Identificador: </label>
                <Input
                  action={{
                    color: 'teal',
                    labelPosition: 'right',
                    icon: 'copy',
                    content: 'Copiar',
                    onClick: () => copyToClipboard(this.props.identificador)
                  }}
                  defaultValue={this.props.identificador}
                  readOnly
                />
              </Form.Field>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            onClick={() => {
              this.props.onClose()
              this.props.history.push(`/`)
            }}
          >
            Encerrar
          </Button>
          <Button
            content="Download"
            icon="file pdf"
            onClick={() => {
              var link = document.createElement('a')
              link.download = 'Requerimento'
              link.href = this.props.url
              link.click()
            }}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}
