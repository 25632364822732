import { History } from 'history'
import * as React from 'react'
import {
  Checkbox,
  Header,
  Table,
  Container,
  Message,
  Icon,
  Segment,
  Pagination,
  Input,
  Select,
  Button,
  Popup,
  Form
} from 'semantic-ui-react'

import { Requerimento } from '../types/Requerimento'
import {
  deleteRequerimento,
  getRequerimentos,
  updateRequerimentoEditavelField,
  updateRequerimentoPublicoField
} from '../api/requerimentos-api'
import { Link } from 'react-router-dom'
import { RequerimentoRemoverConfirmacaoModal } from './modalMessages/RequerimentoRemoverConfirmacaoModal'
import { TipoDocumentoEnum } from '../types/TipoDocumentoEnum'
import { Auth } from 'aws-amplify'

interface RequerimentosListProps {
  history: History
  showHideLoader: any
  setShowAuthentication: any
}

interface RequerimentosListState {
  requerimentos: Requerimento[]
  loaderStatusOfRequerimentsForEditavelField: boolean[]
  loaderStatusOfRequerimentsForPublicoField: boolean[]
  isAdmin: boolean
  activePage: any
  tipo: string
  idToken: string
}

export class RequerimentosList extends React.PureComponent<
  RequerimentosListProps,
  RequerimentosListState
> {
  state: RequerimentosListState = {
    requerimentos: [],
    loaderStatusOfRequerimentsForEditavelField: [],
    loaderStatusOfRequerimentsForPublicoField: [],
    isAdmin: false,
    activePage: 1,
    tipo: TipoDocumentoEnum.AVERBACAO_REGISTRO,
    idToken: ''
  }

  async componentDidMount() {
    this.props.setShowAuthentication(false)
    this.setState(
      {
        idToken: (await Auth.currentSession()).getIdToken().getJwtToken()
      },
      () => this.atualiza()
    )
  }

  async atualiza() {
    try {
      this.props.showHideLoader()
      const requerimentos = await getRequerimentos(this.state.idToken, '')
      this.setState(
        {
          requerimentos,
          isAdmin: true,
          loaderStatusOfRequerimentsForEditavelField: new Array(
            requerimentos.length
          ).fill(false)
        },
        () => this.props.showHideLoader()
      )
    } catch (e) {
      this.setState({ requerimentos: [] }, () => this.props.showHideLoader())
    }
  }

  render() {
    return (
      <Container>
        <Header as="h1">Requerimentos</Header>
        {this.state.isAdmin ? this.renderTable() : this.renderNegativeMessage()}
      </Container>
    )
  }

  atualizarClick = () => {
    this.atualiza()
  }

  options = [
    { key: 'identificador', text: 'Identificador', value: 'identificador' },
    { key: 'cpf', text: 'CPF', value: 'cpf' },
    { key: 'email', text: 'E-mail', value: 'email' }
  ]

  tiopDocOptions = [
    {
      key: 'a',
      text: 'Ato de Averbação/Registro',
      value: TipoDocumentoEnum.AVERBACAO_REGISTRO
    }
  ]

  renderTable() {
    return (
      <div>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={2}>
                <Form.Field
                  control={Select}
                  options={this.tiopDocOptions}
                  label={{
                    children: 'Tipo:',
                    htmlFor: 'form-select-control-tipo'
                  }}
                  defaultValue={this.state.tipo}
                  placeholder="Tipo"
                  searchInput={{ id: 'form-select-control-tipo' }}
                  selection
                />
              </Table.HeaderCell>
              <Table.HeaderCell colSpan={5}>
                <Input type="text" action disabled>
                  <input />
                  <Select
                    compact
                    options={this.options}
                    defaultValue="articles"
                  />
                </Input>
                <Button icon="search" disabled />
                <Button icon="delete" disabled />

                <Popup
                  trigger={
                    <Button
                      onClick={this.atualizarClick}
                      floated="right"
                      icon="redo"
                    />
                  }
                  position="top center"
                >
                  Atualizar
                </Popup>
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Requerimento</Table.HeaderCell>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>CPF</Table.HeaderCell>
              <Table.HeaderCell>PDF</Table.HeaderCell>
              <Table.HeaderCell>Editável</Table.HeaderCell>
              <Table.HeaderCell>Público</Table.HeaderCell>
              <Table.HeaderCell>Remover</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.requerimentos.map((requerimento, pos) => {
              return (
                <Table.Row key={requerimento.identificador}>
                  <Table.Cell>
                    <Link
                      to={`/requerimento/${requerimento.identificador}/${requerimento.cpf}/editar`}
                    >
                      {requerimento.identificador}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{requerimento.nome}</Table.Cell>
                  <Table.Cell>{requerimento.cpf}</Table.Cell>
                  <Table.Cell collapsing textAlign="center">
                    <a
                      href={`${requerimento.pdfUrl}`}
                      download="Requerimento"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon name="file pdf" />
                    </a>
                  </Table.Cell>
                  <Table.Cell collapsing textAlign="center">
                    <Segment
                      vertical
                      textAlign="center"
                      style={{ boxShadow: 'unset', padding: 'unset' }}
                    >
                      {this.state.loaderStatusOfRequerimentsForEditavelField[
                        pos
                      ] && <div className="ui active tiny centered loader" />}
                      {!this.state.loaderStatusOfRequerimentsForEditavelField[
                        pos
                      ] && (
                        <Checkbox
                          fitted
                          onChange={() => this.onEditavelCheck(pos)}
                          checked={
                            requerimento.editavel &&
                            new Date(requerimento.editavelAt).getDate() >=
                              new Date().getDate() - 1
                          }
                        />
                      )}
                    </Segment>
                  </Table.Cell>
                  <Table.Cell collapsing textAlign="center">
                    <Segment
                      vertical
                      textAlign="center"
                      style={{ boxShadow: 'unset', padding: 'unset' }}
                    >
                      {this.state.loaderStatusOfRequerimentsForPublicoField[
                        pos
                      ] && <div className="ui active tiny centered loader" />}
                      {!this.state.loaderStatusOfRequerimentsForPublicoField[
                        pos
                      ] && (
                        <Checkbox
                          fitted
                          onChange={() => this.onPublicoCheck(pos)}
                          checked={requerimento.publico}
                        />
                      )}
                    </Segment>
                  </Table.Cell>
                  <Table.Cell collapsing textAlign="center">
                    <Segment
                      vertical
                      textAlign="center"
                      style={{ boxShadow: 'unset', padding: 'unset' }}
                    >
                      <RequerimentoRemoverConfirmacaoModal
                        {...this.props}
                        onSuccess={this.removerRequerimento.bind(this)}
                        pos={pos}
                        parentState={this.state}
                      />
                    </Segment>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="16">
                <Pagination
                  disabled
                  floated="right"
                  totalPages={0}
                  onPageChange={this.onPageChange}
                  boundaryRange={0}
                  defaultActivePage={1}
                  ellipsisItem={null}
                  firstItem={null}
                  lastItem={null}
                  siblingRange={1}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    )
  }

  async removerRequerimento(pos: number) {
    const requerimentoItemBackup = Object.assign(this.state.requerimentos[pos])
    try {
      this.setState(
        (prevRequerimentosListState): RequerimentosListState => {
          const requerimentosCopy = [
            ...prevRequerimentosListState.requerimentos
          ]
          requerimentosCopy.splice(pos, 1)

          return {
            requerimentos: requerimentosCopy,
            loaderStatusOfRequerimentsForEditavelField:
              prevRequerimentosListState.loaderStatusOfRequerimentsForEditavelField,
            loaderStatusOfRequerimentsForPublicoField:
              prevRequerimentosListState.loaderStatusOfRequerimentsForPublicoField,
            isAdmin: prevRequerimentosListState.isAdmin,
            activePage: prevRequerimentosListState.activePage,
            tipo: prevRequerimentosListState.tipo,
            idToken: prevRequerimentosListState.idToken
          }
        },
        async () => {
          await deleteRequerimento(
            this.state.idToken,
            requerimentoItemBackup.identificador,
            requerimentoItemBackup.cpf
          )
        }
      )
    } catch (e) {
      this.setState((prevRequerimentosListState): RequerimentosListState => {
        const requerimentosCopy = [...prevRequerimentosListState.requerimentos]
        requerimentosCopy.splice(pos, 0, requerimentoItemBackup)

        return {
          requerimentos: requerimentosCopy,
          loaderStatusOfRequerimentsForEditavelField:
            prevRequerimentosListState.loaderStatusOfRequerimentsForEditavelField,
          loaderStatusOfRequerimentsForPublicoField:
            prevRequerimentosListState.loaderStatusOfRequerimentsForPublicoField,
          isAdmin: prevRequerimentosListState.isAdmin,
          activePage: prevRequerimentosListState.activePage,
          tipo: prevRequerimentosListState.tipo,
          idToken: prevRequerimentosListState.idToken
        }
      })
      throw new Error('Erro ao se tentar remover um requerimento')
    }
  }

  renderNegativeMessage() {
    return (
      <div>
        <Message negative>
          <Message.Header>Ação não permitida</Message.Header>
          <p>Usuário não tem permissão para listar requisitos</p>
        </Message>
      </div>
    )
  }

  onPageChange = (e: any, pageInfo: any) => {
    console.log('pageInfo:', JSON.stringify(pageInfo))
    alert('clicked' + JSON.stringify(pageInfo))
    //setActivePage(pageInfo.activePage);
    //setApiUrl('https://swapi.co/api/people/?page=' + page.activePage.toString());
  }

  onEditavelCheck = async (pos: number) => {
    const currentEditalvelStatus =
      this.state.requerimentos[pos].editavel &&
      new Date(this.state.requerimentos[pos].editavelAt).getDate() >=
        new Date().getDate() - 1
    console.log('current status', currentEditalvelStatus)
    try {
      this.setState(
        (prevRequerimentosListState): RequerimentosListState => {
          const loaderStatusOfRequerimentsCopy = [
            ...prevRequerimentosListState.loaderStatusOfRequerimentsForEditavelField
          ]
          loaderStatusOfRequerimentsCopy[pos] = true

          return {
            requerimentos: prevRequerimentosListState.requerimentos,
            loaderStatusOfRequerimentsForEditavelField:
              loaderStatusOfRequerimentsCopy,
            loaderStatusOfRequerimentsForPublicoField:
              prevRequerimentosListState.loaderStatusOfRequerimentsForPublicoField,
            isAdmin: prevRequerimentosListState.isAdmin,
            activePage: prevRequerimentosListState.activePage,
            tipo: prevRequerimentosListState.tipo,
            idToken: prevRequerimentosListState.idToken
          }
        },
        async () => {
          await updateRequerimentoEditavelField(
            this.state.idToken,
            this.state.requerimentos[pos]
          )
          this.setState(
            (prevRequerimentosListState): RequerimentosListState => {
              const requerimentosCopy = [
                ...prevRequerimentosListState.requerimentos
              ]
              requerimentosCopy[pos].editavelAt = new Date().toISOString()
              requerimentosCopy[pos].editavel = !currentEditalvelStatus
              console.log('new status', requerimentosCopy[pos].editavel)

              const loaderStatusOfRequerimentsCopy = [
                ...prevRequerimentosListState.loaderStatusOfRequerimentsForEditavelField
              ]
              loaderStatusOfRequerimentsCopy[pos] = false

              return {
                requerimentos: requerimentosCopy,
                loaderStatusOfRequerimentsForEditavelField:
                  loaderStatusOfRequerimentsCopy,
                loaderStatusOfRequerimentsForPublicoField:
                  prevRequerimentosListState.loaderStatusOfRequerimentsForPublicoField,
                isAdmin: prevRequerimentosListState.isAdmin,
                activePage: prevRequerimentosListState.activePage,
                tipo: prevRequerimentosListState.tipo,
                idToken: prevRequerimentosListState.idToken
              }
            }
          )
        }
      )
    } catch (e) {
      this.setState((prevRequerimentosListState): RequerimentosListState => {
        const loaderStatusOfRequerimentsCopy = [
          ...prevRequerimentosListState.loaderStatusOfRequerimentsForEditavelField
        ]
        loaderStatusOfRequerimentsCopy[pos] = false

        return {
          requerimentos: prevRequerimentosListState.requerimentos,
          loaderStatusOfRequerimentsForEditavelField:
            loaderStatusOfRequerimentsCopy,
          loaderStatusOfRequerimentsForPublicoField:
            prevRequerimentosListState.loaderStatusOfRequerimentsForPublicoField,
          isAdmin: prevRequerimentosListState.isAdmin,
          activePage: prevRequerimentosListState.activePage,
          tipo: prevRequerimentosListState.tipo,
          idToken: prevRequerimentosListState.idToken
        }
      })
      throw new Error(
        'Erro ao se tentar atualizar o esta editavel de um requerimento'
      )
    }
  }

  onPublicoCheck = async (pos: number) => {
    const currentPublicoStatus = this.state.requerimentos[pos].publico
    console.log('current status', currentPublicoStatus)
    try {
      this.setState(
        (prevRequerimentosListState): RequerimentosListState => {
          const loaderStatusOfRequerimentsForPublicoFieldCopy = [
            ...prevRequerimentosListState.loaderStatusOfRequerimentsForPublicoField
          ]
          loaderStatusOfRequerimentsForPublicoFieldCopy[pos] = true

          return {
            requerimentos: prevRequerimentosListState.requerimentos,
            loaderStatusOfRequerimentsForEditavelField:
              prevRequerimentosListState.loaderStatusOfRequerimentsForEditavelField,
            loaderStatusOfRequerimentsForPublicoField:
              loaderStatusOfRequerimentsForPublicoFieldCopy,
            isAdmin: prevRequerimentosListState.isAdmin,
            activePage: prevRequerimentosListState.activePage,
            tipo: prevRequerimentosListState.tipo,
            idToken: prevRequerimentosListState.idToken
          }
        },
        async () => {
          await updateRequerimentoPublicoField(
            this.state.idToken,
            this.state.requerimentos[pos]
          )
          this.setState(
            (prevRequerimentosListState): RequerimentosListState => {
              const requerimentosCopy = [
                ...prevRequerimentosListState.requerimentos
              ]
              requerimentosCopy[pos].publico = !currentPublicoStatus
              console.log('new status', requerimentosCopy[pos].publico)

              const loaderStatusOfRequerimentsForPublicoFieldCopy = [
                ...prevRequerimentosListState.loaderStatusOfRequerimentsForPublicoField
              ]
              loaderStatusOfRequerimentsForPublicoFieldCopy[pos] = false

              return {
                requerimentos: requerimentosCopy,
                loaderStatusOfRequerimentsForEditavelField:
                  prevRequerimentosListState.loaderStatusOfRequerimentsForEditavelField,
                loaderStatusOfRequerimentsForPublicoField:
                  loaderStatusOfRequerimentsForPublicoFieldCopy,
                isAdmin: prevRequerimentosListState.isAdmin,
                activePage: prevRequerimentosListState.activePage,
                tipo: prevRequerimentosListState.tipo,
                idToken: prevRequerimentosListState.idToken
              }
            }
          )
        }
      )
    } catch (e) {
      this.setState((prevRequerimentosListState): RequerimentosListState => {
        const loaderStatusOfRequerimentsCopy = [
          ...prevRequerimentosListState.loaderStatusOfRequerimentsForPublicoField
        ]
        loaderStatusOfRequerimentsCopy[pos] = false

        return {
          requerimentos: prevRequerimentosListState.requerimentos,
          loaderStatusOfRequerimentsForEditavelField:
            prevRequerimentosListState.loaderStatusOfRequerimentsForEditavelField,
          loaderStatusOfRequerimentsForPublicoField:
            loaderStatusOfRequerimentsCopy,
          isAdmin: prevRequerimentosListState.isAdmin,
          activePage: prevRequerimentosListState.activePage,
          tipo: prevRequerimentosListState.tipo,
          idToken: prevRequerimentosListState.idToken
        }
      })
      throw new Error(
        'Erro ao se tentar atualizar o esta editavel de um requerimento'
      )
    }
  }
}
