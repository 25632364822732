import * as React from 'react'
import { Icon, Menu } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'

interface MenuOptionsProps {
  showHideMainMenuFunction: any
}

export default class MenuOptions extends React.Component<MenuOptionsProps> {
  render() {
    return (
      <div>
        <Menu.Item
          as={NavLink}
          to="/"
          name="Home"
          exact
          onClick={this.props.showHideMainMenuFunction}
        >
          <Icon name="home" />
          Home
        </Menu.Item>
        <Menu.Item
          as={NavLink}
          to="/admin"
          name="Administração"
          exact
          onClick={this.props.showHideMainMenuFunction}
        >
          <Icon name="block layout" />
          Administração
        </Menu.Item>
      </div>
    )
  }
}
