import * as React from 'react'
import { Menu, Segment, Sidebar } from 'semantic-ui-react'
import RouterAndContentArea from './components/RouterAndContentArea'
import Footer from './components/Footer'
import MenuOptions from './components/MenuOptions'
import AppHeader from './components/AppHeader'
import FullPageLoader from './components/FullPageLoader'
import { FullPageLoaderConfig } from './types/FullPageLoaderConfig'
import { Authenticator } from '@aws-amplify/ui-react'

export interface AppState {
  visible: boolean
  fullPageLoaderConfig: FullPageLoaderConfig
  showAuthentication: boolean
}

export interface AppProps {
  history: any
}

export default class App extends React.Component<AppProps, AppState> {
  state: AppState = {
    visible: false,
    fullPageLoaderConfig: { show: false, message: '' },
    showAuthentication: false
  }

  showHideMainMenu = (): void => {
    this.setState({ visible: !this.state.visible })
  }

  showHideLoader = (): void => {
    this.setState({
      fullPageLoaderConfig: {
        show: !this.state.fullPageLoaderConfig.show,
        message: 'Processando...'
      }
    })
  }

  setShowAuthentication(toShow: boolean) {
    console.log('setShowAuthentication called... value: ' + toShow)
    this.setState({ showAuthentication: toShow })
  }

  setShowAuthenticationAndGoToAdmin(toShow: boolean) {
    this.setState({ showAuthentication: toShow }, () =>
      this.props.history.push(`/admin`)
    )
  }

  render() {
    return (
      <Authenticator.Provider>
        <div>
          <Sidebar.Pushable as={Segment}>
            <FullPageLoader config={this.state.fullPageLoaderConfig} />
            <Sidebar
              as={Menu}
              animation="overlay"
              icon="labeled"
              inverted
              onHide={() => this.setState({ visible: false })}
              vertical
              visible={this.state.visible}
            >
              <MenuOptions showHideMainMenuFunction={this.showHideMainMenu} />
            </Sidebar>
            <Sidebar.Pusher
              dimmed={this.state.visible}
              style={{ height: '100vh' }}
            >
              <div
                style={{
                  //see some additional required styles in index.css
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%'
                }}
              >
                {/* the following section is fixed to the top */}
                <AppHeader
                  showHideMainMenuFunction={this.showHideMainMenu}
                  setShowAuthentication={this.setShowAuthentication.bind(this)}
                />

                {/* the following section is in the middle, and it can scroll */}
                <div
                  style={{
                    flexGrow: 1,
                    overflowX: 'hidden',
                    overflowY: 'auto'
                  }}
                >
                  <div>
                    <Segment
                      vertical
                      style={{ overflow: 'hidden', maxHeight: '100%' }}
                    >
                      <RouterAndContentArea
                        {...this.props}
                        showHideLoader={this.showHideLoader}
                        showAuthentication={this.state.showAuthentication}
                        setShowAuthentication={this.setShowAuthentication.bind(
                          this
                        )}
                        setShowAuthenticationAndGoToAdmin={this.setShowAuthenticationAndGoToAdmin.bind(
                          this
                        )}
                      />
                    </Segment>
                  </div>
                </div>
                {/* the following section is fixed to the bottom */}
                <Footer />
              </div>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </div>
      </Authenticator.Provider>
    )
  }
}
