export function copyToClipboard(texto: string): void {
  navigator.clipboard.writeText(texto)
}

/*
Exemplo de chamada e de output: 
uuidReadable('f81d4fae-7dec-11d0-a765-00a0c91e6bf6')); // "5jJQzXJ3qWg"
*/
export function uuidReadable(uuid: string) {
  const fold = uuid.substr(0, 8) + uuid.substr(-8)
  const int = parseInt(fold, 16)
  const base62 =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  let res = ''
  let num = int
  while (num > 0) {
    res = base62[num % 62] + res
    num = Math.floor(num / 62)
  }
  return res.padStart(11, '0')
}

export function isUserLoggedIn(): boolean {
  return getIdToken() !== ''
}

export function getIdToken(): string {
  for (var i = 0, len = localStorage.length; i < len; i++) {
    const key = localStorage.key(i) || ''
    if (key.indexOf('.idToken') !== -1) {
      return localStorage[key]
    }
  }
  return ''
}
