import React from 'react'
import { Router, Route } from 'react-router-dom'
import App from './App'
import FullPageLoader from './components/FullPageLoader'
export const history = require('history').createBrowserHistory()

export const makeAuthRouting = () => {
  return (
    <Router history={history}>
      <div>
        <Route
          path="/callback"
          render={() => {
            return (
              <FullPageLoader config={{ show: true, message: 'Loading' }} />
            )
          }}
        />
        <Route
          render={(props) => {
            return <App {...props} />
          }}
        />
      </div>
    </Router>
  )
}
