import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib'

export async function generatePdf(
  rascunho: boolean,
  nome: string,
  cpf: string,
  email: string,
  lote: string,
  includeUri: boolean = true
): Promise<PDFDocument> {
  const url = 'https://pdf-lib.js.org/assets/with_update_sections.pdf'
  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer())

  const pdfDoc = await PDFDocument.load(existingPdfBytes)
  pdfDoc.setCreator('CRUD-PB')
  pdfDoc.setTitle('Requerimento')
  pdfDoc.setAuthor('CRUD-PB')
  pdfDoc.setSubject('Requerimento gerado por CRUD-PB')
  pdfDoc.setKeywords(['requerimento'])
  pdfDoc.setProducer('CRUD-PB')
  pdfDoc.setCreator('CRUD-PB (https://www.crud-pb.com.br)')
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

  const pages = pdfDoc.getPages()
  const firstPage = pages[0]
  const { width, height } = firstPage.getSize()
  if (rascunho) {
    firstPage.drawText('RASCUNHO', {
      x: 210,
      y: 450,
      size: 50,
      font: helveticaFont,
      color: rgb(0.95, 0.1, 0.1),
      rotate: degrees(-45)
    })
  }
  firstPage.drawText(nome, {
    x: 313,
    y: 100,
    size: 10,
    font: helveticaFont,
    color: rgb(0.95, 0.1, 0.1) // red
  })
  firstPage.drawText(cpf, {
    x: 313,
    y: 123,
    size: 10,
    font: helveticaFont,
    color: rgb(0.95, 0.1, 0.95) // blue
  })
  firstPage.drawText(email, {
    x: 60,
    y: 123,
    size: 10,
    font: helveticaFont,
    color: rgb(0.95, 0.3, 0.1) // green
  })
  firstPage.drawText(lote, {
    x: 271,
    y: 77,
    size: 10,
    font: helveticaFont,
    color: rgb(0.95, 0.3, 0.1) // green
  })
  return pdfDoc
}

export async function getPdfAsBase64(
  rascunho: boolean,
  nome: string,
  cpf: string,
  email: string,
  lote: string,
  includeUri: boolean = true
): Promise<string> {
  const pdfDoc = await generatePdf(rascunho, nome, cpf, email, lote)

  const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true })
  return pdfDataUri
}

export async function getPdfAsBytes(
  rascunho: boolean,
  nome: string,
  cpf: string,
  email: string,
  lote: string,
  includeUri: boolean = true
): Promise<Uint8Array> {
  const pdfDoc = await generatePdf(rascunho, nome, cpf, email, lote)

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await pdfDoc.save()
  return pdfBytes
}
